<template>
  <div class="w-100">
    <h5 class="title">{{ title }}</h5>
    <hr>
    <b-form class="row" @submit.prevent="save()">
      <div class="form-group col-6">
        <label for="type">{{ $t('type') }}</label>
        <select name="type" id="type" class="form-control" v-validate="'required'" :state="validateState('type')" v-model="product.type" @change="changeTextImg()">
          <option v-for="type in types" :key="type.value" :value="type.value">{{ type.text | toCapitalize }}</option>
        </select>
        <span class="error-inputs">{{ errors.first('type') }}</span>
      </div>
      <div class="form-group col-6">
        <label for="name">{{ $t('name') }}</label>
        <b-form-input name="name" id="name" v-validate="'required|max:50'" :state="validateState('name')" v-model="product.name"></b-form-input>
        <span class="error-inputs">{{ errors.first('name') }}</span>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="form-group col-12 col-sm-4">
            <label for="fileLg">{{ $t('findImage') }} - <small class="text-muted">*.png, *.jpg, *.jpeg | {{ textImgLg }}</small></label>
            <div class="custom-file" :class="validateState('fileLg') ? 'is-valid' : 'is-invalid'">
              <input type="file" class="custom-file-input" id="fileLg" :lang="lang" @change="imagePreview($event, 'lg')" v-validate="'image|ext:png,jpg,jpeg|size:1024'" accept=".png,.jpeg,.jpg" name="fileLg" :data-vv-as="$t('image') | toLowerCase">
              <label class="custom-file-label" ref="lblFileNameLg" for="fileLg">{{ $t('findImage') }}</label>
            </div>
            <span class="error-inputs">{{ errors.first('fileLg') }}</span>
          </div>
          <div class="form-group col-12 col-sm-4" v-if="disabledMd">
            <label for="fileMd">{{ $t('findImage') }} - <small class="text-muted">*.png, *.jpg, *.jpeg | {{ textImgMd }}</small></label>
            <div class="custom-file" :class="validateState('fileMd') ? 'is-valid' : 'is-invalid'">
              <input type="file" class="custom-file-input" id="fileMd" :lang="lang" @change="imagePreview($event, 'md')" v-validate="'image|ext:png,jpg,jpeg|size:1024'" accept=".png,.jpeg,.jpg" name="fileMd" :data-vv-as="$t('image') | toLowerCase">
              <label class="custom-file-label" ref="lblFileNameMd" for="fileMd">{{ $t('findImage') }}</label>
            </div>
            <span class="error-inputs">{{ errors.first('fileMd') }}</span>
          </div>
          <div class="form-group col-12 col-sm-4" v-if="disabledSm">
            <label for="fileSm">{{ $t('findImage') }} - <small class="text-muted">*.png, *.jpg, *.jpeg | {{ textImgSm }}</small></label>
            <div class="custom-file" :class="validateState('fileSm') ? 'is-valid' : 'is-invalid'">
              <input type="file" class="custom-file-input" id="fileSm" :lang="lang" @change="imagePreview($event, 'sm')" v-validate="'image|ext:png,jpg,jpeg|size:1024'" accept=".png,.jpeg,.jpg" name="fileSm" :data-vv-as="$t('image') | toLowerCase">
              <label class="custom-file-label" ref="lblFileNameSm" for="fileSm">{{ $t('findImage') }}</label>
            </div>
            <span class="error-inputs">{{ errors.first('fileSm') }}</span>
          </div>
        </div>
      </div>
      <div class="form-group col-12">
        <label for="description">{{ $t('description') }}</label>
        <b-form-input type="text" name="description" id="description" v-validate="'max:150'" :state="validateState('description')" v-model="product.description"></b-form-input>
        <span class="error-inputs">{{ errors.first('description') }}</span>
      </div>
      <div class="form-group col-6 col-sm-4">
        <label for="price_1">{{ $t('price') }} x 3 {{ $tc('month', 2) }}</label>
        <b-form-input type="number" id="price_1" name="price_1" v-validate="'required|numeric'" :state="validateState('price_1')" v-model="product.price_1" :data-vv-as="$t('price')"></b-form-input>
        <span class="error-inputs">{{ errors.first('price_1') }}</span>
      </div>
      <div class="form-group col-6 col-sm-4">
        <label for="price_2">{{ $t('price') }} x 6 {{ $tc('month', 2) }}</label>
        <b-form-input type="number" name="price_2" id="price_2" v-validate="'required|numeric'" :state="validateState('price_2')" v-model="product.price_2" :data-vv-as="$t('price')"></b-form-input>
        <span class="error-inputs">{{ errors.first('price_2') }}</span>
      </div>
      <div class="form-group col-6 col-sm-4">
        <label for="price_3">{{ $t('price') }} x 12 {{ $tc('month', 2) }}</label>
        <b-form-input type="number" name="price_3" id="price_3" v-validate="'required|numeric'" :state="validateState('price_3')" v-model="product.price_3" :data-vv-as="$t('price')"></b-form-input>
        <span class="error-inputs">{{ errors.first('price_3') }}</span>
      </div>
      <div class="form-group col-6">
        <label for="visible">{{ $t('visible') }}</label>
        <select name="visible" id="visible" class="form-control" v-validate="'required'" :state="validateState('visible')" v-model="product.visible">
          <option value="1">{{ $t('yes') }}</option>
          <option value="0">{{ $t('not') }}</option>
        </select>
        <span class="error-inputs">{{ errors.first('visible') }}</span>
      </div>
      <div class="form-group col-6">
        <label for="category_id">{{ $tc('category') | toCapitalize }}</label>
        <select name="category_id" id="category_id" class="form-control" v-validate="'required'" :state="validateState('category_id')" v-model="product.category_id" :data-vv-as="$tc('category', 1)" @change="filterSubcategories()">
          <option v-for="cat in categories" :key="cat.id" :value="cat.id">{{ cat.name }}</option>
        </select>
        <span class="error-inputs">{{ errors.first('category_id') }}</span>
      </div>
      <div class="form-group col-6">
        <label for="subcategory_id">Sub {{ $tc('category') }}</label>
        <select name="subcategory_id" id="subcategory_id" class="form-control" v-validate="'required'" :state="validateState('subcategory_id')" v-model="product.subcategory_id" :data-vv-as="`sub ${$tc('category', 1)}`">
          <option v-for="cat in subcategories" :key="cat.id" :value="cat.id">{{ cat.name }}</option>
        </select>
        <span class="error-inputs">{{ errors.first('subcategory_id') }}</span>
      </div>
      <div class="form-group col-6" v-if="product.type === 'CA'">
        <label for="season">{{ $tc('season', 1) |toCapitalize }}</label>
        <select name="season" id="season" class="form-control" v-validate="'required|numeric'" :state="validateState('season')" v-model="product.year" :data-vv-as="$tc('season', 1)">
          <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
        </select>
        <span class="error-inputs">{{ errors.first('season') }}</span>
      </div>
      <div class="col-12">
        <b-button type="submit" variant="primary" class="mr-2">{{ btnText }}</b-button>
        <b-button @click="$router.go(-1)">{{ $t('cancel') }}</b-button>
      </div>
    </b-form>
  </div>  
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      product: {},
      fileSm: null,
      fileMd: null,
      fileLg: null,
      productId: null,
      title: null,
      btnText: null,
      types: [],
      textImgLg: null,
      textImgMd: null,
      textImgSm: null,
      disabledMd: false,
      disabledLg: false,
      categories: [],
      subcategories: [],
      originalCategories: [],
      originalSubcategories: [],
      years: []
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console'
    ])
  },
  created () {
    this.types = this.productTypes()
    this.productId = this.$route.params.productId
    if (this.productId) {
      this.title = this.$t('editProduct')
      this.btnText = this.$t('toUpdate')
    } else {
      this.btnText = this.$t('save')
      this.title = this.$t('createProduct')
    }
    const date = new Date()
    const year = date.getFullYear()
    for (let index = year; index >= 2017; index--) {
      this.years.push(index)
    }
    this.fetchData()
  },
  methods: {
    changeTextImg () {
      const type = this.product.type
      this.disabledMd = true
      this.disabledSm = true
      if (type == 'BT' || type == 'BD') {
        this.textImgLg = '1900 x 240px';
        this.textImgMd = '1024 x 195px';
        this.textImgSm = '768 x 170px';
      } else if(type == 'BE') {
        this.textImgLg = '1900 x 230px';
        this.textImgMd = '1024 x 200px';
        this.textImgSm = '768 x 180px';
      } else if(type == 'BU') {
        this.textImgLg = '1900 x 460px';
        this.textImgMd = '1024 x 460px';
        this.textImgSm = '768 x 440px';
      } else if(type === 'CA') {
        this.textImgLg = 'Normal';
        this.textImgMd = 'Premium';
        this.disabledMd = true
        this.disabledSm = false
      } else {
        this.textImgSm = '';
        this.disabledMd = false
        this.disabledSm = false
      }
      this.categories = this.originalCategories.filter(cat => {
        return cat.type === this.product.type
      })
    },
    filterSubcategories () {
      this.subcategories = this.originalSubcategories.filter(sub => {
        return sub.category_id === this.product.category_id
      })
    },
    fetchData () {
      if (!this.productId) {
        const path = `auth/${this.lang}/console/${this.console}/shop/product/create`
        this.$axios.get(path).then(response => {
          this.originalCategories = response.data.categories
          this.originalSubcategories = response.data.subcategories;
        })
      } else {
        const path = `auth/${this.lang}/console/${this.console}/shop/product/${this.productId}/edit`
        this.$axios.get(path).then(response => {
          this.originalCategories = response.data.categories
          this.originalSubcategories = response.data.subcategories;
          this.product = response.data.product
          this.changeTextImg()
          this.filterSubcategories()
        })
      }
    },
    save () {
      this.$validator.validate().then(result => {
        if (result) {
          let path = null
          if (this.productId) {
            path = `auth/${this.lang}/console/${this.console}/shop/product/${this.productId}/update`
          } else {
            path = `auth/${this.lang}/console/${this.console}/shop/product/store`
          }
          const payload = new FormData();
          payload.append('type', this.product.type);
          payload.append('name', this.product.name);
          payload.append('description', this.product.description);
          payload.append('visible', this.product.visible);
          payload.append('price_1', this.product.price_1);
          payload.append('price_2', this.product.price_2);
          payload.append('price_3', this.product.price_3);
          payload.append('category_id', this.product.category_id);
          payload.append('subcategory_id', this.product.subcategory_id);
          if (this.product.type === 'CA') {
            payload.append('year', this.product.year);
          }
          if (this.fileLg) {
            payload.append('image', this.fileLg);
          }
          if (this.fileMd) {
            payload.append('image_md', this.fileMd);
          }
          if (this.fileSm) {
            payload.append('image_sm', this.fileSm);
          }
          this.$axios.post(path, payload).then(response => {
            this.$toastr.success(response.data.message, window.TITLE_SUCCESS)
            this.$router.go(-1)
          })
        } else {
          this.showValidationsErrors(this.errors.items)
        }
      })
    },
    setImageToProperty (input) {
      const values = {}
      if (input.files && input.files[0]) {
        const file = input.files[0]
        values.file = file
        values.name = file.name
        var reader = new FileReader()
        reader.onload = function () {}
        reader.readAsDataURL(file)
      } else {
        values.file = []
        values.name =  this.$t('findImage')
      }
      return values
    },
    imagePreview (e, type) {
      const image = this.setImageToProperty(e.target)
      if (type === 'sm') {
        this.fileSm = image.file
        this.$refs.lblFileNameSm.innerHTML = image.name
      } else if (type === 'md') {
        this.fileMd = image.file
        this.$refs.lblFileNameMd.innerHTML = image.name
      } else {
        this.fileLg = image.file
        this.$refs.lblFileNameLg.innerHTML = image.name
      }
    }
  }
}
</script>
